import digitalizacionAPI from "../../API/digitalizacionAPI";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { cerrarSesion } from "../../store/autentificacion/autentificacionSlice";
import { useNavigate } from "react-router-dom";


export const useResumenROM = () => {
    const { token } = useSelector((state) => state.autentificacion);
    const conf_api = { headers: { 'x-token': token }};
    const XLSX = require("xlsx-js-style");
    const moment = require('moment');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const obtenerROMAPI = async() => {
        try {
            let datos_refino = {};
            await digitalizacionAPI.get('/rom/refino_rom', conf_api).then(({ data }) => {
                datos_refino = data;
            })
    
            let datos_dinamicos = {};
            await digitalizacionAPI.get('/rom/ils_pilas_dinamicas', conf_api).then(({ data }) => {
                datos_dinamicos = data;
            });
    
            let datos_ils_parshall = {};
            await digitalizacionAPI.get('/rom/ils_parshall', conf_api).then(({ data }) => {
                datos_ils_parshall = data;
            });
    
            let datos_ils_bypass = {};
            await digitalizacionAPI.get('/rom/ils_bypass', conf_api).then(({ data }) => {
                datos_ils_bypass = data;
            });

            // console.log('------- Refino');
            // console.log(datos_refino);
            // console.log('------- Dinámicos');
            // console.log(datos_dinamicos);
            // console.log('------- Parshall');
            // console.log(datos_ils_parshall);
            // console.log('------- Bypass');
            // console.log(datos_ils_bypass);
    
            const datos = organizarDatos( datos_refino, datos_dinamicos, datos_ils_parshall, datos_ils_bypass );

            return datos;

        } catch( err ) {
            console.log(err);
            if( err.response.data == 'Token inválido | TokenExpiredError: jwt expired' ) {
                localStorage.setItem("err-token", true);
                dispatch( cerrarSesion() );
            } else {
                // fs.appendFile(`../../../logs/error_ROM_${ moment().format('DD-MM-AAAA') }.txt`, err.response.data, function (err) {
                //     if (err) throw err;
                // });
            }
        }
    }

    const organizarDatos = ( datos_refino, datos_dinamicos, datos_ils_parshall, datos_ils_bypass ) => {
        // Filtrar datos para evitar repetidos
        const datos_parshall_filtrados = datos_ils_parshall.filter( function( item, index, input) {
            return input.indexOf(item) == index;
        });
        const datos_bypass_filtrados = datos_ils_bypass.filter( function( item, index, input) {
            return input.indexOf(item) == index;
        });


        // Siempre tendrán el mismo largo así que sirve cualquier de las tres
        let filas_turno_a = [];
        let filas_turno_b = [];

        // Extraer valores por turno
        for( let i = 0; i < datos_refino.length; i++ ) {
            const  { fecha } = datos_refino[i];
            filas_turno_a = [ ...filas_turno_a,  generarFila( fecha, 'ta', datos_refino[i].turno_a, datos_dinamicos[i].turno_a, datos_parshall_filtrados[i].turno_a, datos_bypass_filtrados[i].turno_a ) ];
            filas_turno_b = [ ...filas_turno_b,  generarFila( fecha, 'tb', datos_refino[i].turno_b, datos_dinamicos[i].turno_b, datos_parshall_filtrados[i].turno_b, datos_bypass_filtrados[i].turno_b ) ];
        }

        const { filas:filas_resumen, fechas, refino, produccion } = generarFilasResumen( datos_refino, datos_dinamicos, datos_parshall_filtrados, datos_bypass_filtrados );

        return {
            filas_turno_a,
            filas_turno_b,
            filas_resumen,
            fechas,
            refino,
            produccion
        }
    }

    const generarFila = ( fecha, turno, datos_refino, datos_dinamicos, datos_ils_parshall, datos_ils_bypass ) => {
        return [
            { input: false, error: false, valor: fecha, clases: 'fecha' },

            { input: false, error: false, valor: datos_refino.horas, clases: '' },
            { input: false, error: false, valor: (datos_refino.m3).toFixed(2), clases: '' },
            { input: false, error: false, valor: (datos_refino.cu_gpl).toFixed(2), clases: '' },

            { input: false, error: false, valor: datos_dinamicos.horas, clases: '' },
            { input: false, error: false, valor: (datos_dinamicos.m3).toFixed(2), clases: '' },
            { input: false, error: false, valor: (datos_dinamicos.cu_gpl).toFixed(2), clases: '' },

            { campo: 'parshall', input: true, disabled: true, error: false, original: datos_ils_parshall.horas, valor: datos_ils_parshall.horas, clases: '' },
            { campo: 'parshall', input: true, disabled: true, error: false, original: (datos_ils_parshall.m3_hora).toFixed(2), valor: (datos_ils_parshall.m3_hora).toFixed(2), clases: '' },
            { input: false, error: false, valor: (datos_ils_parshall.m3_turno).toFixed(2), clases: '' },
            { input: false, error: false, valor: (datos_ils_parshall.cu_gpl).toFixed(2), clases: '' },

            { campo: 'bypass', input: true, disabled: true, error: false, original: datos_ils_bypass.horas, valor: datos_ils_bypass.horas, clases: '' },
            { campo: 'bypass', input: true, disabled: true, error: false, original: (datos_ils_bypass.m3_hora).toFixed(2), valor: (datos_ils_bypass.m3_hora).toFixed(2), clases: '' },
            { input: false, error: false, valor: (datos_ils_bypass.m3_turno).toFixed(2), clases: '' },
            { input: false, error: false, valor: (datos_ils_bypass.cu_gpl).toFixed(2), clases: '' },
        ]
    }

    const generarFilasResumen = ( datos_refino, datos_dinamicos, datos_ils_parshall, datos_ils_bypass ) => {
        let filas = [];
        let fechas = [];
        let refino = []; // grafico
        let produccion = []; // grafico
        let acumulado = 0.00;

        for( let i = 0; i < datos_refino.length; i++ ) {
            const { fecha, total_dia:valores_refino } = datos_refino[i];
            const { total_dia:valores_dinamicos } = datos_dinamicos[i];
            const { total_dia:valores_parshall } = datos_ils_parshall[i];
            const { total_dia:valores_bypass } = datos_ils_bypass[i];

            // (ton_parshall + ton_bypass + ton_pila_dinamica) - ton_refino
            const ton_cu_dia = (( valores_parshall.ton_cu + valores_bypass.ton_cu + valores_dinamicos.ton_cu ) - valores_refino.ton_cu).toFixed(2);

            fechas = [ ...fechas, fecha ];
            refino = [ ...refino, valores_refino.ton_cu ];
            produccion = [...produccion, ton_cu_dia ];
            acumulado = acumulado + parseFloat(ton_cu_dia);

            

            filas = [ ...filas, [
                { disabled: true, error: false, valor: fecha, clases: 'fecha' },
                
                { disabled: true, error: false, valor: valores_refino.horas, clases: '' },
                { disabled: true, error: false, valor: valores_refino.m3, clases: '' },
                { disabled: true, error: false, valor: valores_refino.ton_cu, clases: '' },

                { disabled: true, error: false, valor: valores_dinamicos.horas, clases: '' },
                { disabled: true, error: false, valor: valores_dinamicos.m3, clases: '' },
                { disabled: true, error: false, valor: valores_dinamicos.ton_cu, clases: '' },

                { disabled: true, error: false, valor: valores_parshall.horas, clases: '' },
                { disabled: true, error: false, valor: valores_parshall.m3_hora, clases: '' },
                { disabled: true, error: false, valor: valores_parshall.m3, clases: '' },
                { disabled: true, error: false, valor: valores_parshall.ton_cu, clases: '' },

                { disabled: true, error: false, valor: valores_bypass.horas, clases: '' },
                { disabled: true, error: false, valor: valores_bypass.m3_hora, clases: '' },
                { disabled: true, error: false, valor: valores_bypass.m3, clases: '' },
                { disabled: true, error: false, valor: valores_bypass.ton_cu, clases: '' },

                { disabled: true, error: false, valor: ton_cu_dia, clases: '' },
                { disabled: true, error: false, valor: acumulado.toFixed(2), clases: '' }
            ]]


        }

        return {
            filas,
            fechas,
            refino,
            produccion
        };
    }

    const descargarROM = ( datos ) => {
        // Generar hojas
        const hoja_resumen = generarHoja( 'general', datos.filas_resumen );
        const hoja_turno_a = generarHoja( 'a', datos.filas_turno_a );
        const hoja_turno_b = generarHoja( 'b', datos.filas_turno_b );

        // Generar archivo
        const archivo = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(archivo, hoja_resumen, `Resumen`);
        XLSX.utils.book_append_sheet(archivo, hoja_turno_a, `Turno A`);
        XLSX.utils.book_append_sheet(archivo, hoja_turno_b, `Turno B`);

        // Descargar
        XLSX.writeFile(
            archivo, 
            `Resumen_producción_ROM_${ moment().format('DD-MM-YYYY') }.xlsx`, 
            {  editable: true }
        );
    }   

    const generarHoja = ( turno, datos ) => {
        // Estilos de disabled
        const cabecera_claro = {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: turno == 'general' ? '6f8e9e' : turno == 'a' ? '429ba3' : 'da6846' 
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        const subcabecera_claro = {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: turno == 'general' ? '2566b4' : turno == 'a' ? '53b2bb' : 'e69981' 
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        const cabecera_oscuro = {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: turno == 'general' ? '607d8b' : turno == 'a' ? '00838f' : 'c25838' 
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        const subcabecera_oscuro = {
            font: {
                sz: 12, // tamaño
                bold: true, // grosor
                color: {
                    rgb: 'ffffff'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: turno == 'general' ? '16539e' : turno == 'a' ? '429ba3' : 'e0896e' 
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        const estilo_fecha = {
            font: {
                sz: 11, // tamaño
                bold: true, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: turno == 'general' ? 'd0ddec' : turno == 'a' ? 'bfdde0' : 'f6ddd5' 
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        const disabled_gris_claro = {
            font: {
                sz: 11, // tamaño
                bold: false, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'f2f2f2'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        const disabled_gris_oscuro = {
            font: {
                sz: 11, // tamaño
                bold: false, // grosor
                color: {
                    rgb: '37474f'  // color de letra
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            },
            fill: {
                fgColor: {
                    rgb: 'e9e9e9'
                }
            },
            border: {
                top: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                bottom: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                right: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                },
                left: {
                    style: 'thin',
                    color: {
                        rgb: 'FFFFFF'
                    }
                }
            }
        };

        // Generar hoja
        let hoja = XLSX.utils.aoa_to_sheet([]);
        let origen = 1;
        let cols = [ 
            { wch: 3 }, { wch: 18 }, 
            { wch: 18 }, { wch: 18 }, { wch: 18 }, 
            { wch: 18 }, { wch: 18 }, { wch: 18 }, 
            { wch: 18 }, { wch: 18 }, { wch: 18 }, 
            { wch: 18 }, { wch: 18 }, { wch: 18 }, 
            { wch: 18 }, { wch: 18 },
            { wch: 18 }, { wch: 18 }
        ];
        let rows =  [{ 'hpt': 15 }, { 'hpt': 21 }, { 'hpt': 21 }];
        let merges = [
            { 
                s: { r: 1, c: 2  },
                e: { r: 1, c: 4  } 
            },
            { 
                s: { r: 1, c: 5  },
                e: { r: 1, c: 7  } 
            },
            { 
                s: { r: 1, c: 8  },
                e: { r: 1, c: 11  } 
            },
            { 
                s: { r: 1, c: 12  },
                e: { r: 1, c: 15  } 
            },
            { 
                s: { r: 1, c: 16  },
                e: { r: 1, c: 17  } 
            },
        ];

        let cabecera = [];
        if ( turno == 'general' ) {
            cabecera = [
                { v: '', t: 's' },
    
                { v: '', t: '', s: cabecera_claro },
    
                { v: 'REFINO A ROM', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
    
                { v: 'ILS PILAS DINÁMICAS', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
    
                { v: 'ILS POR PARSHAL A CANALETAS', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
    
                { v: 'ILS BYPASS A PISCINAS PLS', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
    
                { v: 'PRODUCCIÓN ROM', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro }
            ];
        } else {
            cabecera = [
                { v: '', t: 's' },
    
                { v: '', t: '', s: cabecera_claro },
    
                { v: 'REFINO A ROM', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
    
                { v: 'ILS PILAS DINÁMICAS', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
    
                { v: 'ILS POR PARSHAL A CANALETAS', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
                { v: '', t: '', s: cabecera_claro },
    
                { v: 'ILS BYPASS A PISCINAS PLS', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
                { v: '', t: '', s: cabecera_oscuro },
            ];
        }

        // Agregar cabecera
        XLSX.utils.sheet_add_aoa(hoja, [cabecera], { origin: origen  });
        origen += 1;

        if ( turno == 'general' ) {
            cabecera = [
                { v: '', t: 's' },
    
                { v: 'FECHA', t: 's', s: subcabecera_claro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_claro },
                { v: 'M3/DÍA', t: '', s: subcabecera_claro },
                { v: 'TON/DÍA', t: '', s: subcabecera_claro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_oscuro },
                { v: 'M3/DÍA', t: '', s: subcabecera_oscuro },
                { v: 'TON/DÍA', t: '', s: subcabecera_oscuro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_claro },
                { v: 'M3/HORA', t: '', s: subcabecera_claro },
                { v: 'M3/DÍA', t: '', s: subcabecera_claro },
                { v: 'TON/DÍA', t: '', s: subcabecera_claro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_oscuro },
                { v: 'M3/HORA', t: '', s: subcabecera_oscuro },
                { v: 'M3/DÍA', t: '', s: subcabecera_oscuro },
                { v: 'TON/DÍA', t: '', s: subcabecera_oscuro },
    
                { v: 'TON CU/DÍA', t: '', s: subcabecera_claro },
                { v: 'TON CU ACUM.', t: '', s: subcabecera_claro }
            ];
        } else {
            cabecera = [
                { v: '', t: 's' },
    
                { v: 'FECHA', t: 's', s: subcabecera_claro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_claro },
                { v: 'M3/TURNO', t: '', s: subcabecera_claro },
                { v: '[CU] GPL', t: '', s: subcabecera_claro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_oscuro },
                { v: 'M3/TURNO', t: '', s: subcabecera_oscuro },
                { v: '[CU] GPL', t: '', s: subcabecera_oscuro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_claro },
                { v: 'M3/HORA', t: '', s: subcabecera_claro },
                { v: 'M3/TURNO', t: '', s: subcabecera_claro },
                { v: '[CU] GPL', t: '', s: subcabecera_claro },
    
                { v: 'HRS OPERACIÓN', t: '', s: subcabecera_oscuro },
                { v: 'M3/HORA', t: '', s: subcabecera_oscuro },
                { v: 'M3/TURNO', t: '', s: subcabecera_oscuro },
                { v: '[CU] GPL', t: '', s: subcabecera_oscuro },
            ];
        }

        // Agregar cabecera
        XLSX.utils.sheet_add_aoa(hoja, [cabecera], { origin: origen  });
        origen += 1;

        datos.forEach( fila_tabla => {
            let fila = [ { v: '', t: 's' } ];

            for( let i = 0; i < fila_tabla.length; i++ ) {
                if( i == 0 ) {
                    fila = [...fila, { v: fila_tabla[i].valor, t: '', s: estilo_fecha }]

                } else if( (i >= 1 && i <= 3) || (i >= 7 && i <= 10) || i == 15 || i == 16 ) { 
                    fila = [...fila, { v: fila_tabla[i].valor, t: '', s: disabled_gris_claro }]
                } else {
                    fila = [...fila, { v: fila_tabla[i].valor, t: '', s: disabled_gris_oscuro }]
                }
            }

            XLSX.utils.sheet_add_aoa(hoja, [fila], { origin: origen });
            origen += 1;

            rows =  [ ...rows, { 'hpt': 21 }];
        });

        hoja['!merges'] = merges;
        hoja['!rows'] = rows;
        hoja['!cols'] = cols;

        return hoja;
    }

    const guardarCambiosAPI = async( datos ) => {
        const { filas_turno_a, filas_turno_b } = datos;

        // Extraer campos
        const campos_parshall_a = filas_turno_a[ filas_turno_a.length - 1 ].filter( celda => { return celda.input == true && celda.campo == 'parshall' });
        const campos_bypass_a = filas_turno_a[ filas_turno_a.length - 1 ].filter( celda => { return celda.input == true && celda.campo == 'bypass' });
        const campos_parshall_b = filas_turno_b[ filas_turno_b.length - 1 ].filter( celda => { return celda.input == true && celda.campo == 'parshall' });
        const campos_bypass_b = filas_turno_b[ filas_turno_b.length - 1 ].filter( celda => { return celda.input == true && celda.campo == 'bypass' });
        
        try {
            await guardarVariables( campos_parshall_a, 'a', 'parshall' );
            await guardarVariables( campos_bypass_a, 'a', 'bypass' );

            await guardarVariables( campos_parshall_b, 'b', 'parshall' );
            await guardarVariables( campos_bypass_b, 'b', 'bypass' );

            navigate(0);

        } catch( err ) {
            console.log( err );
        }
    }

    const guardarVariables = async( campos, turno, variables ) => {
        const existe_cambio = campos.filter( campo => { return campo.original != campo.valor });
        
        if( existe_cambio.length > 0 ) {
            const aux = `
            {
                "horas_${ variables }": ${ parseInt( campos[0].valor ) },
                "m3_${ variables }": ${ parseInt( campos[1].valor ) },
                "turno": "${ turno }"
            }
            `;

            const json = JSON.stringify( JSON.parse( aux ) );
            // console.log(json);
            const res = await digitalizacionAPI.post(`rom_inputs/${ variables }`, json, {
                headers: {
                    "x-token": token,
                    "Content-Type": 'application/json'
                }
            });;
        }
    }

    return {
        obtenerROMAPI,
        descargarROM,
        guardarCambiosAPI
    }
}