import { useEffect, useState } from 'react'
import { Grafico } from './Grafico';

export const Estadistica = (props) => {
  const [DatosGraficos, setDatosGraficos] = useState({ pila: false });
  const [NuevaSerie, setNuevaSerie] = useState([]);
  const [SerieEste, setSerieEste] = useState([]);
  const [SerieOeste, setSerieOeste] = useState([]);

  useEffect(() => {
    // Gráficos met: Rlix pila este y oeste =>  Módulos, Rlix objetivo (1.7), Rlix real
    // Son arrays solo con los valores
    if(props.planta == 'met' && props.modulos.length > 0) {
      DatosMET(props.modulos);
      
    } else if(props.planta == 'oxe' && props.modulos.length > 0) {
      DatosOXE(props.modulos);
    }
  }, [props.modulos])

  const DatosMET = (modulos) => {
      let datos_este = {};
      let modulos_pila = [];
      let rlix_objetivo = [];
      let rlix_estimado = [];
      let rlix_real = [];
      
      if(modulos.length >= 50) {
        let contador = 0;
        modulos.forEach(modulo => {
          if(modulo.Nomenclatura != null) {
            if(contador < 51) { // Pila este
            modulos_pila = [...modulos_pila, modulo.Nomenclatura];
            rlix_objetivo = [...rlix_objetivo, 1.7];
            rlix_real = [...rlix_real, modulo.RazonRiego_Efectiva];
            rlix_estimado = [...rlix_estimado, modulo.Rlix_Estimado];
  
          } else if(contador == 51) { // Registrar pila este
            datos_este = {
              modulos: modulos_pila,
              rlix_objetivo,
              rlix_real
            };
  
            
            let data = [];
            for(let i = 0; i < modulos_pila.length; i++) {
              const modulo = modulos_pila[i];
              const real = rlix_real[i];
              const estimado = rlix_estimado[i];
        
              data = [...data, {
                  x: modulo,
                  y: real,
                  goals: [
                    {
                      name: 'Rlix esperado',
                      value: estimado,
                      // strokeWidth: 10,
                      strokeHeight: 5,
                      strokeDashArray: 30,
                      strokeColor: '#ffc33c'
                    }
                  ]
                }
              ];
            }
            setSerieEste([
              { name: 'Rlix real',
                type: 'bar',
                data
              }
            ]);

            modulos_pila = [modulo.Nomenclatura];
            rlix_objetivo = [1.7];
            rlix_real = [modulo.RazonRiego_Efectiva];
  
          } else {
            modulos_pila = [...modulos_pila, modulo.Nomenclatura];
            rlix_objetivo = [...rlix_objetivo, 1.7];
            rlix_real = [...rlix_real, modulo.RazonRiego_Efectiva];
          }
  
          contador += 1;
          
          }
        });

        let data = [];
        for(let i = 0; i < modulos_pila.length; i++) {
          const modulo = modulos_pila[i];
          const real = rlix_real[i];
          const estimado = rlix_estimado[i];
    
          data = [...data, {
              x: modulo,
              y: real,
              goals: [
                {
                  name: 'Rlix esperado',
                  value: estimado,
                  // strokeWidth: 10,
                  strokeHeight: 5,
                  strokeDashArray: 30,
                  strokeColor: '#ffc33c'
                }
              ]
            }
          ];
        }

        setSerieOeste([
          { name: 'Rlix real',
            type: 'bar',
            data
          }
        ])
        // Registrar pila oeste
        setDatosGraficos({
          pila: true
          // pila: true,
          // pila_este: {
          //   modulos: datos_este.modulos,
          //   rlix_objetivo: datos_este.rlix_objetivo,
          //   rlix_real: datos_este.rlix_real
          // },
          // pila_oeste: {
          //   modulos: modulos_pila,
          //   rlix_objetivo,
          //   rlix_real
          // }
        });

        

      } else {
        modulos.forEach(modulo => {
          modulos_pila = [...modulos_pila, modulo.modulo];
          rlix_objetivo = [...rlix_objetivo, 1.7];
          rlix_real = [...rlix_real, modulo.RazonRiego_Efectiva];
          rlix_estimado = [...rlix_estimado, modulo.Rlix_Estimado]
        });

        // Registrar pila oeste
        setDatosGraficos({
          pila: true,
          // pila_este: {
          //   modulos: modulos_pila,
          //   rlix_objetivo: rlix_objetivo,
          //   rlix_real: rlix_real
          // },
          // pila_oeste: {
          //   modulos: [],
          //   rlix_objetivo: [],
          //   rlix_real: []
          // }
        });

        let data = [];
        for(let i = 0; i < modulos_pila.length; i++) {
          const modulo = modulos_pila[i];
          const real = rlix_real[i];
          const estimado = rlix_estimado[i];
    
          data = [...data, {
              x: modulo,
              y: real,
              goals: [{
                name: 'Rlix esperado',
                value: estimado,
                strokeWidth: 15,
                strokeHeight: 5,
                strokeDashArray: 30,
                strokeColor: '#775DD0'
              }]
            }
          ];
        }

        setSerieEste([
          {name: 'Rlix real',
          type: 'bar',
          data}
        ]);

        setSerieOeste([
          {name: 'Rlix real',
          type: 'bar',
          data: []}
        ])
      }
  }

  const DatosOXE = ( modulos ) => {
    // Ordenar por franja
    let lista = [ ...modulos ];
    lista.sort(( a, b ) => parseInt(a.Franja) - parseInt(b.Franja));

    let lista_modulos = [];
    let rlix_real = [];
    let rlix_estimado = [];
    let dias_riego_efectivo = [];
    let volumen = [];

    lista.forEach(modulo => {
      lista_modulos = [...lista_modulos, modulo.Nomenclatura];
      rlix_real = [...rlix_real, modulo.rlix_real ];
      rlix_estimado = [...rlix_estimado, modulo.Rlix_Estimado]
      dias_riego_efectivo = [...dias_riego_efectivo, modulo.DiasRiego_Efectivo];

      volumen = [...volumen, modulo.TMS]
    })

    let data = [];

    for(let i = 0; i < lista_modulos.length; i++) {
      const modulo = lista_modulos[i];
      const real = rlix_real[i];
      const estimado = rlix_estimado[i];

      data = [...data, {
          x: modulo,
          y: real,
          goals: [
            {
              name: 'Rlix esperado',
              value: estimado,
              // strokeWidth: 10,
              strokeHeight: 5,
              strokeDashArray: 30,
              strokeColor: '#ffc33c'
            }
          ]
        }
      ];
    }
    
    setNuevaSerie( [
      {
          name: 'Rlix real',
          type: 'bar',
          data
      }
    ]);

    setDatosGraficos({
      pila: true,
      modulos: lista_modulos,
      rlix_objetivo: 1.3,
      rlix_real,
      dias_riego_efectivo,
      volumen
    })
  }
  

  return (
    <div id="tab-graficos-rlix" className="tab-rlix">
      <div className="cont-grafs">
        {
          DatosGraficos.pila == false
          ? <></>
          : props.planta == 'oxe'
            ? <>
                {/* <Grafico 
                  series={ ['Rlix efectiva', 'Rlix objetivo'] }
                  nombre='Rlix efectiva por módulo (m3/ton)' 
                  tipo='doble' 
                  id_grafico='chart-rlix-efectiva' 
                  labels={ DatosGraficos.modulos } 
                  linea_objetivo={DatosGraficos.rlix_objetivo } 
                  valores_barra={ DatosGraficos.rlix_real } 
                /> */}

                <Grafico 
                  series={ NuevaSerie }
                  nombre='Rlix efectiva por módulo (m3/ton)' 
                  tipo='nuevo' 
                  id_grafico='chart-rlix-nuevo'
                  rlix_objetivo={ 1.3 }
                />

                <Grafico 
                  series={ ['Días efectivos', 'Días objetivo'] }
                  nombre='Duración en días del ciclo de riego por módulo' 
                  tipo='doble' 
                  id_grafico='chart-dias-riego' 
                  labels={ DatosGraficos.modulos } 
                  linea_objetivo={ DatosGraficos.dias_objetivo} 
                  valores_barra={ DatosGraficos.dias_riego_efectivo } 
                />

                <Grafico 
                  series={ 'Toneladas' }
                  nombre='Toneladas de mineral por módulo' 
                  tipo='simple' 
                  id_grafico='chart-tonelaje' 
                  labels={ DatosGraficos.modulos } 
                  valores_barra={ DatosGraficos.volumen } 
                />

              </>

            :
            <>
              {/* <Grafico 
                series={ ['Rlix efectiva', 'Rlix objetivo'] }
                nombre='Pila este - Razón de lixiviación (m3/ton)' 
                tipo='doble' 
                id_grafico='chart-pila-este' 
                labels={ DatosGraficos.pila_este.modulos } 
                linea_objetivo={ DatosGraficos.pila_este.rlix_objetivo } 
                valores_barra={ DatosGraficos.pila_este.rlix_real } 
              /> */}

              <Grafico 
                series={ SerieEste }
                nombre='Pila este - Razón de lixiviación (m3/ton)' 
                tipo='nuevo-este' 
                id_grafico='chart-pila-este' 
                rlix_objetivo={ 1.7 }
              />

              <Grafico 
                series={ SerieOeste }
                nombre='Pila oeste - Razón de lixiviación (m3/ton)' 
                tipo='nuevo-este' 
                id_grafico='chart-pila-oeste' 
              />

              {/* <Grafico 
                series={ ['Rlix efectiva', 'Rlix objetivo'] }
                nombre='Pila oeste - Razón de lixiviación (m3/ton)' 
                tipo='doble' 
                id_grafico='chart-pila-oeste' 
                labels={ DatosGraficos.pila_oeste.modulos  } 
                linea_objetivo={ DatosGraficos.pila_oeste.rlix_objetivo } 
                valores_barra={ DatosGraficos.pila_oeste.rlix_real } 
              />    */}
            </>
        }

        <br />

      </div>
    </div>
  )
}
